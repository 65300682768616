import { NgClass, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, input, output, signal } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export interface SearchProps {
  width?: string,
  placeholder?: string,
  color?: string,
  border?: string,
  borderRadius?: string,
  fontWeight?: number,
  backgroundColor?: string,
  placeholderColor?: string,
  value?: string
}

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [
    NgStyle,
    NgClass
  ],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})

/* 
  ┌─────────────────────────────────────────────────────────────────────────┐
  │ // Search input handles debounce by default and is set to 300ms         │
  └─────────────────────────────────────────────────────────────────────────┘
 */

export class SearchComponent implements OnInit, OnDestroy {

  props = input<SearchProps>();
  onSearch = output<string>();
  inputSubject = new Subject<string>();
  searchValue = signal<string>('');

  ngOnInit(): void {
    this.searchValue.set(this.props()?.value ?? '');
    this.inputSubject.pipe(debounceTime(300)).subscribe(value => {
      this.onSearch.emit(value);
    })
  }

  onSearchInput(e: Event) {
    this.searchValue.set((e.target as HTMLInputElement).value);
    this.inputSubject.next(this.searchValue());
  }

  onSearchCleared(e: Event){
    e.stopPropagation();
    this.searchValue.set('');
    this.inputSubject.next(this.searchValue());
  }

  ngOnDestroy(): void {
    this.inputSubject.unsubscribe();
  }

}